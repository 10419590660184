
import { initializeApp } from "firebase/app";

import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_LF5htQ3yrXURoJ1FErmous2rxb3MhmA",
  authDomain: "heroparty-prod.firebaseapp.com",
  projectId: "heroparty-prod",
  storageBucket: "heroparty-prod.firebasestorage.app",
  messagingSenderId: "887774483307",
  appId: "1:887774483307:web:e2baa1ad41a04f104ab22e"
};


const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

// Google Auth provider
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, signInWithPopup, signOut };