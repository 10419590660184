import React, { useState } from 'react';
import { auth, googleProvider, signInWithPopup } from './firebaseConfig';
import { Card, CardContent, Box,  Button, Typography, Stack } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';

const SignUpPage = ({ setUser }) => {
  const [error, setError] = useState(null);
  const [sucess, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async () => {

    try {
      // Sign in with Google via Firebase
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      setUser(user); 
      setSuccess(true);
      console.log(sucess);
      navigate('/home')
    } catch (local_error) {
      setError(local_error.message);
      console.log(error);
    }

  };


  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px' }}>
        <Stack direction="row" spacing={1}>
          <Card sx={{
            border: 'none', 
            boxShadow: 'none',
            color: '#dd9e85', 
            backgroundColor: 'inherit',
          }}>
            <CardContent>
              <Typography variant="h2" sx={{ fontWeight: 'bold'}}>
                  HeroParty
              </Typography>
              <Typography variant="h4">
                  HeroParty helps you understand your data. 
              </Typography>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ color: '#338395', backgroundColor: 'inherit',}}>
            <CardContent>
              <Typography variant="h4" align="center" sx={{ fontWeight: 'bold'}} gutterBottom>
                Sign up
              </Typography>
              <Button
                onClick={handleSignUp}
                startIcon={<GoogleIcon />} 
                type="submit"
                fullWidth
                variant="outlined"
                sx={{
                  marginTop: 1, 
                  borderColor: '#338395',
                  color: '#338395',
                  fontWeight: 'bold', 
                  fontSize: '20px'
                }}
              > 
                Continue with Google
              </Button>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </div>
  );
}

export default SignUpPage;
