// src/components/About.js
import React from 'react';

import { 
    Container, 
    Typography,
    Card,
    CardMedia,
    Stack,
    CardContent,
} from '@mui/material';


const Home = ({ user }) => {
    
    return (
        <Container sx={{
            width: '80%'
        }}>
            <Stack spacing={2}>
                <Card sx={{ backgroundColor: "inherit", border: 'none', boxShadow: 'none', color: "#338395"}}>
                    <CardMedia
                        component="img"
                        image={user.photoURL}  
                        sx={{ borderRadius: '50%', width: '100px' }}
                    />
                    <CardContent>
                        <Typography variant="h4">
                            Hello {user.displayName}
                        </Typography>
                        <Typography variant="h6">
                           Thanks for signing up, we're still building our product! Thanks for expressing your interest. 
                        </Typography>
                        <Typography variant="h6">
                           Check your emails for product updates! 
                        </Typography>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    );
};

export default Home;
