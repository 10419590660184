import React from 'react';
import { Container, Typography, Stack, Card, CardMedia, CardContent, Divider } from '@mui/material';

function BlogPage() {
  return (
      <Container sx={{
        width: '80%'
      }}>
        <Stack spacing={4}>
          <Card sx={{ backgroundColor: 'inherit', color: '#338395',  border: 'none', boxShadow: 'none',}}>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
              Blogs
            </Typography>
          </Card>
          <Card sx={{ backgroundColor: '#338395', color: 'white'}}>
            <CardMedia
              component="img"
              image="https://picsum.photos/800/150"
              alt="Blog image"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                What is Zero-Party Data? 
              </Typography>
              <Typography variant="p">
                  Zero-party data refers to information that a user intentionally and proactively share with a company. 
                  This data can include personal preferences, purchase intentions, and feedback on products or services. 
                  <Divider sx={{ my: 1 }}/>
                  Unlike third-party data, which is collected indirectly, zero-party data comes directly from a user, making it highly reliable and privacy-friendly.
                  It is valuable for businesses because it helps them personalise experiences and build stronger customer relationships while respecting privacy regulations.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ backgroundColor: '#338395', color: 'white'}}>
            <CardMedia
              component="img"
              image="https://picsum.photos/800/150"
              alt="Blog image"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                What is a SAR? 
              </Typography>
              <Typography variant="p">
                  A Subject Access Request (SAR) is a formal request made by an individual to a company, asking for access to the personal data the company holds about them.
                  <Divider sx={{ my: 1 }}/>
                  Under data protection laws, such as the General Data Protection Regulation (GDPR) in the European Union and the Data Protection Act 2018 in the UK, 
                  individuals have the right to know what personal data is being collected, how it's being used, and who it’s shared with.
              </Typography>
            </CardContent>
          </Card>
        </Stack>
      </Container>
  );
}

export default BlogPage;
